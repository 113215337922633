<template>
  <div
    class="mt-1 mb-1"
  >
    <div class="d-flex flex-column align-items-center" style="">
      <div class="w-100">
        <vue-slider
          v-model="modifiedSalaryRange"
          :enable-cross="false"
          class="m-0 p-0 w-100 pt-2 cursor-pointer"
          :contained="true"
          :silent="true"
          :tooltip="'always'"
          :min="min_salary_range"
          :max="max_salary_range"
          :interval="1"
          :tooltip-formatter="salary_formatter"
        />
          <!-- :tooltip-placement="['left', 'right']" -->
      </div>

      <div class="mt-1 d-flex flex-row flex-gap-sm h-100 w-100">
        <b-button class="flex-fill" :variant="salary_range_cycle == 'monthly' ? 'primary' : 'outline-primary'" @click="salary_range_cycle = 'monthly'">Monthly</b-button>
        <b-button class="flex-fill" :variant="salary_range_cycle == 'yearly' ? 'primary' : 'outline-primary'" @click="salary_range_cycle = 'yearly'">Yearly</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  components: {
    VueSlider,
  },

  props: {
    salaryRange: {
      type: Array,
      default: () => [0, 0],
    },
    minSalaryRange: {
      type: Number,
      default: 0,
    },
    maxSalaryRange: {
      type: Number,
      default: 0,
    },
    placeholder: {
      type: String,
      default: 'Adjust the salary range',
    },
  },

  data() {
    return {
      modifiedSalaryRange: this.salaryRange,
      salary_range_cycle: 'monthly',
      salary_formatter: v => `S$${`${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
      min_salary_range: this.minSalaryRange,
      max_salary_range: this.maxSalaryRange,
    }
  },

  computed: {
  },

  watch: {
    modifiedSalaryRange(newValue, oldValue) {
      let result = newValue
      switch (this.salary_range_cycle) {
        case 'yearly':
          result = result.map(value => Math.ceil(value / 12))
          break;

        case 'monthly':
          break;

        default:
          break;
      }
      this.$emit('update', result)
    },
    salary_range_cycle(newValue, oldValue) {
      switch (newValue) {
        case 'yearly':
          this.min_salary_range *= 12
          this.max_salary_range *= 12
          this.modifiedSalaryRange = this.modifiedSalaryRange.map(value => value * 12)
          break;

        case 'monthly':
          this.min_salary_range /= 12
          this.max_salary_range /= 12
          this.modifiedSalaryRange = this.modifiedSalaryRange.map(value => value / 12)
          break;

        default:
          break;
      }
      this.$emit('update-salary-range-cycle', newValue)
    },
  },

  mounted() {
  },

  methods: {
    //
  },
}
</script>

<style lang="scss" scoped>
.vue-slider-process {
  background-color: #f37044 !important;
}

.vue-slider-dot-tooltip-text {
  border-color: #f37044 !important;
  background-color: #f37044 !important;
}
.vue-slider-dot-tooltip-inner {
  border-color: #f37044 !important;
  background-color: #f37044 !important;
}

</style>